<template>
  <v-dialog
    v-model="dialog"
    persistent
    :fullscreen="$vuetify.breakpoint.mobile"
    transition="dialog-bottom-transition"
    max-width="1000"
    v-if="isDataLoadedFromServer"
  >
    <div class="bg-white poppins">
      <div class="modal-header align-center">
        <h3 class="mb-0 font-weight-bolder">Add New Service</h3>
        <div
          class="btn btn-sm btn-icon btn-active-light-info"
          @click="toggleModal"
        >
          <span class="svg-icon">
            <v-icon size="22">mdi-close</v-icon>
          </span>
        </div>
      </div>
      <div class="modal-body d-flex flex-column scroll-y mx-5 mx-xl-15 py-7">
        <!--begin::Body-->
        <v-stepper v-model="step">
          <v-stepper-header>
            <v-stepper-step :complete="step > 1" step="1">
              Select type of service
            </v-stepper-step>

            <v-divider></v-divider>

            <v-stepper-step :complete="step > 2" step="2">
              Service
              {{
                formData.service_type_id === 2
                  ? "Storage"
                  : formData.service_type_id === 3
                  ? "Delivery"
                  : formData.service_type_id === 1
                  ? "Fulfillment"
                  : formData.service_type_id === 4
                  ? "Fulfillment and Delivery"
                  : ""
              }}
              form
            </v-stepper-step>
          </v-stepper-header>

          <v-stepper-items>
            <v-stepper-content step="1">
              <v-card elevation="0" class="mb-4">
                <form id="createForm" class="pa-3 pt-0 pb-0 row">
                  <div class="col-sm-6 col-md-6 col-lg-6 col-12">
                    <v-text-field
                      v-model="formData.name"
                      label="Name"
                      clearable
                      dense
                      outlined
                      :error-messages="nameErrors"
                      @input="$v.formData.name.$touch()"
                      @blur="$v.formData.name.$touch()"
                    ></v-text-field>
                  </div>
                  <div class="col-sm-6 col-md-6 col-lg-6 col-12">
                    <v-select
                      v-model="formData.service_type_id"
                      label="Service type"
                      item-text="text"
                      item-value="index"
                      :items="serverData.service_types"
                      clearable
                      dense
                      outlined
                      :error-messages="service_type_idErrors"
                      @input="$v.formData.service_type_id.$touch()"
                      @blur="$v.formData.service_type_id.$touch()"
                    ></v-select>
                  </div>
                  <div class="col-sm-12 col-md-12 col-lg-12 col-12">
                    <v-text-field
                      v-model="formData.description"
                      label="Description"
                      clearable
                      dense
                      outlined
                    ></v-text-field>
                  </div>
                  <v-main class="text-center mt-4">
                    <button
                      type="button"
                      class="btn btn-info px-5 py-3 ls1"
                      @click="handleStage1"
                    >
                      Continue
                    </button>
                  </v-main>
                </form>
              </v-card>
            </v-stepper-content>

            <v-stepper-content step="2">
              <v-card class="mb-4 ma-0 pa-0" elevation="0">
                <component
                  ref="step2Form"
                  v-bind:is="step2FormComponent"
                  :setData="setForm2Data"
                  :actionFuntion="handleStage2"
                  :serverData="serverData"
                  :pageLoader="pageLoader"
                  :initData="false"
                ></component>
              </v-card>
            </v-stepper-content>
          </v-stepper-items>
        </v-stepper>
      </div>
    </div>
  </v-dialog>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import Swal from "sweetalert2";
import ApiService from "@/core/services/api.service";

import DeliveryForm from "@/own/components/finance/services/addForms/DeliveryForm";
import FulfillmentForm from "@/own/components/finance/services/addForms/FulfillmentForm";
import StorageForm from "@/own/components/finance/services/addForms/StorageForm";
import FulfillmentDelivery from "@/own/components/finance/services/addForms/FulfillmentDeliveryForm.vue";

export default {
  name: "AddItem",
  mixins: [validationMixin],
  validations: {
    formData: {
      name: { required },
      service_type_id: { required },
    },
  },
  props: ["refresher", "pageLoader"],
  data: () => ({
    dialog: false,
    step: 1,
    step2Form: null,
    serverData: null,
    isDataEnteredToForm: false,
    formData: {
      name: null,
      description: null,
      service_type_id: null,
      values: {},
    },
  }),
  beforeMount() {
    this.loadDataFromServer();
  },
  methods: {
    handleStage1() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      if (this.formData.service_type_id == 2) {
        this.step2Form = StorageForm;
      } else if (this.formData.service_type_id == 3) {
        this.step2Form = DeliveryForm;
      } else if (this.formData.service_type_id == 1) {
        this.step2Form = FulfillmentForm;
      } else if (this.formData.service_type_id == 4) {
        this.step2Form = FulfillmentDelivery;
      }
      this.$nextTick(() => {
        if (!this.isDataEnteredToForm) {
          this.$refs.step2Form.resetCreateForm();
        }
        this.step = 2;
        this.isDataEnteredToForm = true;
      });
    },
    handleStage2(action = "submit") {
      if (action == "submit") {
        this.submitCreateForm();
      } else {
        this.step = 1;
      }
    },
    setForm2Data(item) {
      // console.log("before ap", this.formData.clients);
      let copy = { ...this.formData };
      copy.values = item;
      this.formData = copy;
      // console.log("after ap", this.formData.clients);
    },
    handleFormValidation(fieldName, vueObj) {
      const errors = [];
      if (!vueObj.$v.formData[fieldName].$dirty) return errors;
      // if ("email" in vueObj.$v[fieldName]) {
      //   !vueObj.$v[fieldName].email &&
      //     errors.push("This email address is invalid");
      // }
      if ("required" in vueObj.$v.formData[fieldName]) {
        !vueObj.$v.formData[fieldName].required &&
          errors.push("This field is required");
      }
      return errors;
    },
    loadDataFromServer() {
      this.pageLoader(true);
      ApiService.post("/cod/finance_services/create")
        .then((response) => {
          this.serverData = response.data;
          this.pageLoader(false);
        })
        .catch(() => {
          this.dialog = false;
          this.loader(false);
        });
    },
    toggleModal() {
      this.resetCreateForm();
      this.dialog = !this.dialog;
      this.step = 1;
      if (this.step2Form) {
        try {
          this.$refs.step2Form.resetCreateForm();
        } catch (e) {
          // console.error(e);
        }
      }
    },
    validateData() {
      return true;
    },
    convertToFormData() {
      // let data = new FormData();
      // Object.entries(this.formData).forEach((item) => {
      //   if (Array.isArray(item[1])) {
      //     item[1].forEach((it) => {
      //       if (it || it == "0") {
      //         data.append(`${item[0]}[]`, it);
      //       }
      //     });
      //   } else if (
      //     typeof item[1] === "object" &&
      //     !Array.isArray(item[1]) &&
      //     item[1] !== null
      //   ) {
      //     Object.entries(item[1]).forEach((el) => {
      //       if (
      //         el[1] &&
      //         (el[0] == "rates_file" || el[0] == "increments_file")
      //       ) {
      //         if (el[1] || el[1] == "0") {
      //           data.append(`${item[0]}[${el[0]}]`, el[1]);
      //         }
      //       } else if (Array.isArray(el[1])) {
      //         el[1].forEach((subEl, i) => {
      //           if (
      //             typeof subEl === "object" &&
      //             !Array.isArray(subEl) &&
      //             subEl !== null
      //           ) {
      //             Object.entries(subEl).forEach((objEl) => {
      //               if (objEl[1] || objEl[1] == "0") {
      //                 data.append(
      //                   `${item[0]}[${el[0]}][${i}][${objEl[0]}]`,
      //                   objEl[1]
      //                 );
      //               }
      //             });
      //           } else {
      //             if (subEl || subEl == "0") {
      //               data.append(`${item[0]}[${el[0]}][]`, subEl);
      //             }
      //           }
      //         });
      //       } else {
      //         if (el[1] || el[1] == "0") {
      //           data.append(`${item[0]}[${el[0]}]`, el[1]);
      //         }
      //       }
      //     });
      //   } else {
      //     if (item[1] || item[1] == "0") {
      //       data.append(item[0], item[1]);
      //     }
      //   }
      // });
      // for (let [key, value] of data) {
      //   console.log(`${key}: ${value}`);
      // }
      // return data;
      return this.formData;
    },
    submitCreateForm() {
      this.pageLoader(true);
      let data = this.convertToFormData();
      ApiService.post("/cod/finance_services/store", data)
        .then(() => {
          Swal.fire({
            title: "Created",
            text: `Record has been successfully added!`,
            icon: "success",
            showConfirmButton: false,
            timer: 2500,
          });
          this.resetCreateForm();
          this.toggleModal();
          this.refresher();
        })
        .catch(() => {
          this.pageLoader(false);
        });
    },
    resetCreateForm() {
      this.$v.$reset();
      this.formData = {
        name: null,
        description: null,
        service_type_id: null,
        values: {},
      };
    },
  },
  computed: {
    isDataLoadedFromServer: function () {
      return !!this.serverData;
    },
    step2FormComponent: function () {
      return this.step2Form;
    },
    nameErrors() {
      return this.handleFormValidation("name", this);
    },
    service_type_idErrors() {
      return this.handleFormValidation("service_type_id", this);
    },
  },
  watch: {
    "formData.service_type_id": function (newValue, oldValue) {
      if (newValue != oldValue) {
        this.isDataEnteredToForm = false;
      }
    },
  },
};
</script>
